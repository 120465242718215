<template>
  <div>
    <p></p>
    <div class="row">
      <div class="col">{{ $t('reports.reportstransactions.beginningOfPeriod') }}</div>
      <div class="col">{{ $t('reports.reportstransactions.endOfPeriod') }}</div>
      <div class="col">{{ $t('reports.reportstransactions.timezone') }}</div>
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <el-config-provider :locale="langEl">
          <el-date-picker
              v-model="filterData.ds"
              type="datetime"
              :format="dataformatEl"
              :disabled-date="disabledDate"
          />
        </el-config-provider>
      </div>

      <div class="col">
        <el-config-provider :locale="langEl">
          <el-date-picker
              v-model="filterData.de"
              type="datetime"
              :format="dataformatEl"
          />
        </el-config-provider>
      </div>

      <div class="col">
        <el-select v-model="filterData.tz" filterable placeholder="Select">
          <el-option
              v-for="item in tz_list"
              :key="item.id"
              :label="item.text"
              :value="item.id"
          />
        </el-select>
      </div>

      <div class="col">
        <button @click="run" class="btn btn-success m-1"><span v-if="!is_run">{{ $t('reports.reportsoverview.build') }}</span><span v-else>{{ $t('reports.reportsoverview.update') }}</span></button>
      </div>
    </div>

    <p></p>

    <el-table
        :data="dataForParts"
        style="width: 100%"
        border
    >
      <el-table-column v-for="column in columnsTable" :key="column.field" :prop="column.field" :label="column.label" :min-width="column.minwidth">
        <template #header v-if="column.field=='public_id'">
          {{$t('reports.reportseventsdetailed.public_id')}}<br>
          <el-input
              v-model="filtersEl.public_id"
              size="small"
              :placeholder="$t('reports.reportseventsdetailed.public_id')"
              @change="filterTableDataFn"
          />
        </template>

        <template v-if="column.field=='action'" #default="props">
          <span class="cursor-pointer" @click="recalcEvent(props.row.event_id)">
            <font-awesome-icon icon="calculator" />
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, provide, reactive, ref} from "vue";
import WizardsService from "../../services/wizards.service";

const $t = inject('$t')

const set_result = inject('set_result')

const currentUser = inject('currentUser')
const tz_list = inject('tz_list')
const dataformatEl=inject('dataformatEl')
const langEl=inject('langEl')
const is_run = ref(false)
const setIsLoading = inject('setIsLoading')
const runProcess = inject('runProcess')
const date_to_format = inject('date_to_format')

const data_type = inject('data_type')

let date_start=ref(new Date());
let cday=new Date();
let date_end=ref(new Date(cday.setDate(cday.getDate()+1)))

date_start.value=new Date(date_start.value.toDateString());
date_end.value=new Date(date_end.value.toDateString());

function disabledDate(time)
{
  let res=false

  if (time.getTime() > Date.now()) {
    res=true
  }

  let cdayd=new Date();
  let date_from=new Date(cdayd.setDate(cdayd.getDate()-10)).valueOf()
  if (time.getTime() < date_from) {
    res=true
  }

  return res
}

const currentUserTZ = computed( ()=>currentUser.value.timezone)

const filterData = reactive({
  ds: date_start,
  de: date_end,
  tz: 'UTC+0'
})

const filtersEl = ref({'public_id': ''})
const filtersOptionsEl = ref({})

function fieldsTable()
{
  let fld = []

  fld.push({label: $t('wizards.eventprocessormanual.datetime'), field: 'datetime'})
  fld.push({label: $t('wizards.eventprocessormanual.game'), field: 'game'})
  fld.push({label: $t('wizards.eventprocessormanual.public_id'), field: 'public_id'})
  fld.push({label: $t('wizards.eventprocessormanual.bets_count'), field: 'bets_count'})
  fld.push({label: $t('app.action'), field: 'action'})

  return fld
}
const columnsTable = computed(fieldsTable)

function updatefilterData(data)
{
  if(data.ds!=undefined) {
    filterData.ds = data.ds
  }

  if(data.de!=undefined) {
    filterData.de = data.de
  }

  if(data.tz!=undefined) {
    filterData.tz = data.tz
  }
}

const data = ref([])
const dataForParts = ref([])

function filterTableDataFn()
{
  let filterData = []

  for (let currow in data.value) {
    let cur = data.value[currow]
    let is_filter_ok = true

    //Номер евента
    if (filtersEl.value.public_id != '') {
      if (filtersEl.value.public_id==cur.public_id) {
        is_filter_ok = false
      }
    }

    if (is_filter_ok == true) {
      filterData.push(cur)
    }
  }

  dataForParts.value=filterData

  return filterData
}

function run()
{
  setIsLoading(true)

  let dstart=date_to_format(filterData.ds)
  let dend=date_to_format(filterData.de)

  let fd={
    date_start: dstart,
    date_end: dend,
    tz: filterData.tz,
  }

  data.value=[]
  WizardsService.eventsFailed(fd).then(
      (response) => {
        data.value=response.data

        dataForParts.value=response.data
        filterTableDataFn()

        is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function recalcEvent(event_id)
{
  WizardsService.eventRecalc({"event_id": event_id}).then(
      () => {
        is_run.value=true
        setIsLoading(false)
        runProcess.value=true

        run()
      },
      (error) => {
        let err_descr = error.response.data
        set_result(error.response.status, error, err_descr)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function mounted_fn()
{
  updatefilterData({tz: currentUserTZ.value})

  run()
}
onMounted(mounted_fn)
</script>

<style scoped>

</style>